<template>
<main ref="buscarUsuarioContainer">
    <data-grid @search="search($event)" :data="pagination">
        <template #grid>
            <grid-table>
                <template #head>
                    <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                </template>
                <template #body>
                    <grid-row v-for="(user, i) in pagination.data" :key="`d_${i}`">
                        <grid-cell class="text-center">
                            {{(user.tipo_documento || {}).codigo}}
                        </grid-cell>
                        <grid-cell>
                            {{user.documento}}
                        </grid-cell>
                        <grid-cell>
                            {{user.nombre_1}} {{user.nombre_2}} {{user.apellido_1}} {{user.apellido_2}}
                        </grid-cell>
                        <grid-cell class="text-center">
                            <button class="btn btn-sm btn-icon btn-success" title="Seleccionar" @click="selected(i)">
                                <i class="fas fa-check"></i>
                            </button>
                        </grid-cell>
                    </grid-row>
                </template>
            </grid-table>
        </template>
    </data-grid>
</main>
</template>

<script>

import DataGrid from "./utilities/DataGrid/DataGrid";
import GridTable from "./utilities/DataGrid/GridTable";
import GridCellHeader from "./utilities/DataGrid/GridCellHeader";
import GridRow from "./utilities/DataGrid/GridRow";
import GridCell from "./utilities/DataGrid/GridCell";
import Swal from 'sweetalert2/src/sweetalert2';
import usuarioService from "../../services/usuarioService";

export default {
    name: "SearchUserComponent",
    components: {GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    data(){
        return {
            headers : ['T. Doc','No. Documento','Nombre Completo',''],
            pagination : {
                data : [],
                current_page : 1,
                page : 1,
                total : 0,
                per_page : 5,
                search : '',
            }
        }
    },
    created() {
        this.index();
    },
    watch : {
        'pagination.page' : {
            handler : function(){
                this.index();
            },
            deep : true
        },
        'pagination.per_page' :  {
            handler : function(){
                this.pagination.page = 1;
                this.index();
            },
            deep : true
        }
    },
    methods : {
        async index(){
            let loader = this.$loading.show({ color: '#0055ff', container: this.$refs.buscarUsuarioContainer });
            try{
                
                const response = ( await usuarioService.index(this.pagination.search, this.pagination) )?.data;

                this.pagination.data = response.data;
                this.pagination.total = response.total;
                this.pagination.current_page = response.current_page;

                loader.hide();
                
            }catch (e) {
                console.error(e);
                loader.hide();
                Swal.fire('Ocurrio un error al procesar la solicitud', '', 'error');
            }
        },
        search(query){
            this.pagination.search = query;
            this.index();
        },
        selected(index){
            const selected = this.pagination.data[index];
            this.$emit('select', selected);
        }
    }
}
</script>

<style scoped>

</style>
