<template>
    <main>
        <div class="row">
            <div :class="{
                'mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-4' : showName!==false,
                'mr-0 pr-0 col-xs-12 col-sm-12 col-md-12 col-lg-12' : showName===false
            }">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control form-control-sm"
                           :readonly="isSearch"
                           v-on:keyup.enter.prevent="getUserByDocument()"
                           v-model="documento"
                           :class="classObject">
                    <div class="input-group-append">
                        <button class="btn btn-primary"
                                data-toggle="modal"
                                :data-target="`#${random_modal_id}`"
                                @click="component='SearchUserComponent'"
                                tabindex="-1">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ml-0 pl-1 col-xs-12 col-sm-12 col-md-8 col-lg-8" v-if="showName!==false">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    v-model="nombre_completo"
                />
            </div>
        </div>
        <div class="modal fade " :id="random_modal_id" tabindex="-1" role="dialog" aria-labelledby="buscarUsuario" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Buscar Usuario</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <component :is="component" @select="onSelect($event)"></component>
                    </div>
                </div>
            </div>
        </div>
    </main>

</template>
<script>
import ServiceFactory from "../../services/ServiceFactory";
import SearchUserComponent from "./SearchUserComponent";
const UsuarioService = ServiceFactory.get("usuario");
import {isEmpty} from 'lodash';
import $ from 'jquery';

export default {
    components: {SearchUserComponent},
    props: ["value", 'validator','showName'],
    data() {
        return {
            documento : '',
            usuario : this.value,
            isSearch : false,
            random_modal_id : '',
            component : '',
        };
    },
    created() {
        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_modal_id = `search_usuario_modal_${random_number}`;
    },
    methods: {
        async getUserByDocument(){

            this.isSearch = true;
            let response = await UsuarioService.getUserByDocument(this.documento);
            this.isSearch = false;

            if(!isEmpty(response.data)){
                this.usuario = response.data
                this.$emit('input', this.usuario);
            }else{
                this.usuario = {};
                this.$emit('input', this.usuario);
            }
        },
        onSelect(user){
            this.documento = user.documento;
            this.getUserByDocument();
            $(`#${this.random_modal_id}`).modal('hide');
        },
    },
    watch : {
        value: {
            handler: function (newValue) {

                this.usuario = newValue;

                if (Object.entries(newValue).length === 0) {
                    this.documento = "";
                    return;
                }

                this.documento = newValue.documento;
            },
            deep: true,
        },
    },
    computed : {
        nombre_completo: function () {
            return !isEmpty(this.usuario)
                ? `${this.usuario.nombre_1} ${this.usuario.nombre_2} ${this.usuario.apellido_1} ${this.usuario.apellido_2}`
                : "";
        },
        classObject: function () {

            let _class = {
                "is-invalid": false,
                "is-valid": false,
            };

            if (this.validator !== undefined && this.validator !== null) {
                _class["is-invalid"] = this.validator.$error;
                _class["is-valid"] = !this.validator.$error && this.validator.$dirty;
            }

            return _class;
        },
    }
};
</script>

